import { template as template_14108f73c68c470d9e59d727a5542582 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_14108f73c68c470d9e59d727a5542582(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
