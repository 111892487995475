import { template as template_21e13c7f3aae43fcb4a2762553f2238a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_21e13c7f3aae43fcb4a2762553f2238a(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
