import { template as template_02eb5bf33adb49baa9526da9435fcedf } from "@ember/template-compiler";
const FKControlMenuContainer = template_02eb5bf33adb49baa9526da9435fcedf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
