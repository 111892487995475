import { template as template_cba37920dd8940279161197eed5ebb27 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cba37920dd8940279161197eed5ebb27(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
