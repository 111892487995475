import { template as template_2745f74ba28b49d7825f74dc6ecb7eeb } from "@ember/template-compiler";
const FKText = template_2745f74ba28b49d7825f74dc6ecb7eeb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
